/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cancel': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.535 5.536a1 1 0 00-1.414 0l-2.12 2.12-2.122-2.12A1 1 0 105.465 6.95l2.12 2.12-2.12 2.123a1 1 0 101.413 1.414L9 10.485l2.121 2.122a1 1 0 001.415-1.415l-2.122-2.121 2.121-2.121a1 1 0 000-1.414z" _fill="#fff"/>'
  }
})
